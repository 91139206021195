import React, {useCallback, useEffect, useState} from 'react';
import {isMobile, isTablet} from 'react-device-detect';
import {connect} from 'react-redux';
import {Route, Switch, useLocation} from 'react-router-dom';
import {useIntercom} from 'react-use-intercom';

import {library} from '@fortawesome/fontawesome-svg-core';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {brandConfig, lazyWithRetry} from '@growth-x/ui';

import {PrivateRoute} from './components/';
import {chargebeeActions} from './redux/chargebee';
import {clientActions} from './redux/client';
import {inboxActions} from './redux/inbox';
import {settingsActions} from './redux/settings';
import {CONSTANTS, isProd} from './services';

import 'antd/dist/antd.less';
import './App.scss';

const SignInPage = lazyWithRetry(() => import('./pages/signin'));
const PasswordResetPage = lazyWithRetry(() => import('./pages/password'));
const UsersPage = lazyWithRetry(() => import('./pages/users'));
const CampaignsPage = lazyWithRetry(() => import('./pages/campaigns'));
const ConnectionsPage = lazyWithRetry(() => import('./pages/connections'));
const ProspectsPage = lazyWithRetry(() => import('./pages/prospects'));
const LazyInboxPage = lazyWithRetry(() => import('./pages/inbox'));
const TemplatesPage = lazyWithRetry(() => import('./pages/templates'));
const TwitterConnectionPage = lazyWithRetry(() => import('./pages/twitter'));
const MaintenancePage = lazyWithRetry(() => import('./pages/maintenance'));
const MemberPage = lazyWithRetry(() => import('./pages/members'));
const AudiencePage = lazyWithRetry(() => import('./pages/audience'));
const SfOauth2PopupPage = lazyWithRetry(() => import('./pages/oauth2/SfOauth2PopupPage'));
const HsOauth2PopupPage = lazyWithRetry(() => import('./pages/oauth2/HsOauth2PopupPage'));
const PurchasePromoPage = lazyWithRetry(() => import('./pages/purchasePromo'));
const TasksPage = lazyWithRetry(() => import('./pages/tasks'));

library.add(fas);
library.add(fab);

interface AppProps {
  client: any;
  users: [] | undefined;
  campaigns: [] | undefined;
  isAdmin: boolean;
  isMaintenance: boolean;
  getClientAndInitialData: () => void;
  updateClientIfNeeded: () => void;
  getInboxUnreadCountByUser: () => void;
  setSettings: (params: any) => void;
  setChargebeeInstance: () => void;
}

const App = ({
  client,
  users,
  campaigns,
  isAdmin,
  isMaintenance,
  getClientAndInitialData,
  getInboxUnreadCountByUser,
  updateClientIfNeeded,
  setSettings,
  setChargebeeInstance,
}: AppProps) => {
  const [userUpdateTimer, setUserUpdateTimer] = useState(null);
  const [isIntercomBooted, setIsIntercomBooted] = useState(false);
  const location = useLocation();
  const [user, setUser] = useState({name: '', email: '', userId: null});

  const {boot, shutdown} = useIntercom();

  const getClientRequest = () => {
    // TODO check if we need this
    const isPublicRoute = location.pathname.match(/(team-member-invite)/g);
    if (!isPublicRoute) {
      getClientAndInitialData();
    }
  };

  const setManifestJson = () => {
    document.title = brandConfig.documentTitle;
    document
      .querySelector('#my-manifest-placeholder')
      .setAttribute('href', `public/${brandConfig.brand}/manifest.json`);
    document
      .querySelector('#description')
      .setAttribute('content', `${brandConfig.name} is an automation tool for social media outreach.`);
    document.querySelector('#author').setAttribute('content', brandConfig.name);
    document
      .querySelector('#shortcutIcon')
      .setAttribute('href', `public/${brandConfig.brand}/${brandConfig.brand}.png`);
    document
      .querySelector('#apple-touch-icon')
      .setAttribute('href', `public/${brandConfig.brand}/${brandConfig.brand}128.png`);
    brandConfig?.variables?.forEach(v => {
      document.querySelector('body').style.setProperty(v.name, v.value);
    });
  };

  useEffect(() => {
    if (brandConfig.isGx && client && isProd()) {
      const {host, pathname} = window.location;
      if (client.beta && !host.includes('beta')) {
        const newUrl = `${CONSTANTS.beta_url}${pathname}`;
        window.location.replace(newUrl);
        return;
      }
      if (!client.beta && host.includes('beta')) {
        const newUrl = `${CONSTANTS.app_url}${pathname}`;
        window.location.replace(newUrl);
        return;
      }
    }
  }, [client]);

  useEffect(() => {
    setManifestJson();
    getClientRequest();
    if (isMobile && !isTablet) {
      setSettings({isNavCollapsed: true});
    }
    const timer = setInterval(() => {
      updateClientIfNeeded();
      getInboxUnreadCountByUser();
    }, CONSTANTS.limits.client_update_interval); // update client every two minutes
    setUserUpdateTimer(timer);
    setChargebeeInstance();
    return () => clearInterval(userUpdateTimer);
  }, []);

  useEffect(() => {
    setUser(getUserData(client));
  }, [client]);

  useEffect(() => {
    if (brandConfig.intercomFeature) {
      try {
        if (user.name !== '' && !isAdmin) {
          boot({...user});
          setIsIntercomBooted(true);
        } else {
          if (isIntercomBooted) {
            shutdown();
            setIsIntercomBooted(false);
          }
        }
      } catch (err) {
        console.error('Intercom error', err);
      }
    }
  }, [user, isAdmin]);

  const getUserData = useCallback(
    (client: any) => ({
      name: client ? client.name : '',
      email: client ? client.email : '',
      userId: client ? client.id : '',
    }),
    []
  );

  if (isMaintenance) return <MaintenancePage />;

  return (
    <div style={{height: 'inherit', width: 'inherit'}}>
      <Switch>
        <Route exact path="/login" component={SignInPage} />
        <Route exact path="/signup" component={() => <SignInPage isSignup={true} />} />
        <Route
          path="/twitter-integration/:connected"
          component={(props: any) => <TwitterConnectionPage success={props.match.params.connected === 'connected'} />}
        />
        <Route path="/team-member-invite" component={MemberPage} />
        <Route exact path="/purchase-promo" component={PurchasePromoPage} />
        {!brandConfig.isAffiliate && <Route exact path="/reset-password" component={PasswordResetPage} />}
        {!brandConfig.isAffiliate && <Route exact path="/set-password" component={PasswordResetPage} />}
        <PrivateRoute exact path="/" component={CampaignsPage} client={client} isLoading={!client || !users} />
        <PrivateRoute path="/users" component={UsersPage} client={client} isLoading={!client || !users} />
        <PrivateRoute path="/prospects" component={ProspectsPage} client={client} isLoading={!client || !users} />
        <PrivateRoute path="/connections" component={ConnectionsPage} client={client} isLoading={!client || !users} />
        <PrivateRoute
          path="/inbox/:user?"
          component={LazyInboxPage}
          client={client}
          isLoading={!client || !users || !campaigns}
        />
        <PrivateRoute
          path="/templates"
          component={TemplatesPage}
          client={client}
          isLoading={!client || !users || !campaigns}
        />
        <PrivateRoute
          path="/audience"
          component={AudiencePage}
          client={client}
          isLoading={!client || !users || !campaigns}
          isAdmin={isAdmin}
        />
        <PrivateRoute path="/tasks" component={TasksPage} client={client} isLoading={!client || !users || !campaigns} />
        <PrivateRoute path="/oauth2/callback" component={SfOauth2PopupPage} client={client} />
        <PrivateRoute path="/hs/oauth/callback" component={HsOauth2PopupPage} client={client} />
      </Switch>
    </div>
  );
};

function mapState(state: any) {
  return {
    client: state.client.data,
    users: state.users.list,
    campaigns: state.campaigns.list,
    isAdmin: state.settings.isAdmin,
    isMaintenance: state.maintenance.isMaintenance,
  };
}
const actionCreators = {
  getClientAndInitialData: clientActions.getClientAndInitialData,
  updateClientIfNeeded: clientActions.updateClientIfNeeded,
  setSettings: settingsActions.setSettings,
  getInboxUnreadCountByUser: inboxActions.getInboxUnreadCountByUser,
  setChargebeeInstance: chargebeeActions.setChargebeeInstance,
};
const ConnectedApp = connect(mapState, actionCreators)(App);
export default ConnectedApp;
